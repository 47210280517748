import {
  Button,
  ComparisonData,
  Comparisons,
  EmptyTable,
  ScoreBar,
} from "components";
import styles from "./styles.module.scss";
import { emptyAssessmentsImg, EyeCloseIcon, EyeOpenIcon } from "assets";
import { MetricHeading, MetricLegend, MetricOverview } from "../components";
import { useState } from "react";

export interface SoftSkillsProps {
  isFullReport: boolean;
  skills: ComparisonData[] | undefined;
  score: number | undefined;
  reportType: string;
}

const SoftSkills: React.FC<SoftSkillsProps> = ({
  isFullReport,
  skills,
  score,
  reportType,
}) => {
  const [moreInsights, setMoreInsights] = useState(false);

  if (score === undefined || !skills)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );

  const getImpact = (score) =>
    interpretation.find((item) => score >= item.min && score <= item.max)
      ?.impact;

  const getImplication = (score) =>
    interpretation.find((item) => score >= item.min && score <= item.max)
      ?.implication;

  const softSkills = skills.map((item) => ({
    ...item,
    body: (
      <>
        <p className={styles.extra_info}>
          <b>Implication:</b> {getImpact(item.result)}
        </p>
        {moreInsights ? (
          <p className={styles.extra_info}>
            <b>Impact on Work:</b> {getImplication(item.result)}
          </p>
        ) : null}
      </>
    ),
  }));

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Soft Skills" /> : ""}

        <Button
          className={styles.moreInsightsBtn}
          onClick={() => setMoreInsights((prev) => !prev)}
          type="transparent"
        >
          {moreInsights ? <EyeCloseIcon /> : <EyeOpenIcon />}
          {!moreInsights ? "See" : "Hide"} more insights
        </Button>
        <section
          className={`${styles.container} ${
            reportType === "observative" ? styles["container--observative"] : ""
          }`}
        >
          {reportType === "observative" ? null : (
            <div className={styles.sec1}>
              <MetricOverview
                description={
                  "Provides insights into the intangible yet essential skills enabling the individual to deliver high-quality work and performance."
                }
                score={score}
              />
              <MetricLegend />
            </div>
          )}
          <Comparisons data={softSkills} reportType={reportType} />
        </section>
      </section>
    </>
  );
};

export { SoftSkills };

const interpretation = [
  {
    max: 100,
    min: 90,
    title: "Exceptional",
    implication: `Demonstrates a high level of expertise, consistently delivering advanced results with minimal supervision. Often sets the standard for others and effectively manages complex challenges. Well-positioned for leadership or roles requiring strategic input.`,
    impact: `Can consistently contribute at a high level, drive key initiatives, and play a critical role in decision-making. Can be trusted to handle complex tasks independently and influence overall performance in this area.`,
  },
  {
    max: 89,
    min: 70,
    title: "Proficient",
    implication: `Possesses a strong and reliable command of the skill, handling most situations effectively with confidence. Adapts well to challenges and requires only occasional support for unfamiliar complexities. With further refinement and experience, can advance to an expert level.`,
    impact: `Can perform competently across various tasks and responsibilities, delivering reliable results. Can handle increasing levels of responsibility with continued exposure and experience.`,
  },
  {
    max: 69,
    min: 50,
    title: "Developing",
    implication: `Has a foundational understanding of the skill and shows clear potential for growth. While not yet performing at an advanced level, can contribute meaningfully in relevant tasks. Targeted training and experience can significantly enhance proficiency.`,
    impact: `Is capable of handling structured or lower-complexity tasks but may require guidance for more advanced responsibilities. Development efforts, such as mentorship or skill-building opportunities, can accelerate growth.`,
  },
  {
    max: 49,
    min: 0,
    title: "Limited",
    implication: `Displays minimal proficiency in this skill, which may indicate a lack of experience or difficulty in application. Likely to face challenges in tasks that rely heavily on this competency. If the skill is essential for their role, focused development will be necessary to improve performance.`,
    impact: `May struggle with tasks requiring this skill, leading to potential performance gaps. If development in this area is a priority, structured learning and practice will be key to improving effectiveness.`,
  },
];
