import { EmptyTable, ProgressBar, ScoreBar } from "components";
import styles from "./styles.module.scss";
import {
  CloseIconCircleFIll,
  TickIconCircleFill,
  emptyAssessmentsImg,
} from "assets";
import { useState } from "react";
import {
  MetricOverview,
  MetricExpectations,
  MetricHeading,
} from "../components";

interface MotivatorsComparisonData {
  metric: string;
  response: string;
  result: string;
}

interface WorkMotivatorsData {
  parameter: string;
  description: string;
  pros: string[];
  cons: string[];
  match: boolean;
  agility?: string[]
  implications?: string[]
}

export interface MotivatorsResultData {
  expectation: string;
  title: string;
  description: string;
  pros: string[];
  cons: string[];
  match: boolean;
}

export interface WorkMotivatorsProps {
  workMotivators: MotivatorsResultData[] | undefined;
  score: number | undefined;
  isFullReport: boolean;
  reportType;
}

const WorkMotivators: React.FC<WorkMotivatorsProps> = ({
  isFullReport,
  workMotivators,
  score,
  reportType,
}) => {
  const [active, setActive] = useState(0);
  const [mobile, setMobile] = useState(window.innerWidth <= 900);

  const screenSizeUpdate = () => {
    setMobile(window.innerWidth <= 900);
  };

  window.addEventListener("resize", screenSizeUpdate);

  if (!workMotivators || score === undefined)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );

  const motivators: MotivatorsComparisonData[] = workMotivators.map(
    (motivator) => ({
      metric: motivator.expectation,
      response: motivator.title,
      result: motivator.match ? "Match" : "Conflict",
    })
  );

  const details: WorkMotivatorsData[] = workMotivators.map((motivator) => ({
    parameter: motivator.title,
    match: motivator.match,
    description: motivator.description,
    pros: motivator.pros,
    cons: motivator.cons,
    ...interpretation.find(
      (item) => motivator.title.toLowerCase().trim() === item.title.toLowerCase().trim()
    ),
  }));

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Work Motivators" /> : ""}
        <section
          className={`${styles.container} ${
            reportType === "observative" ? styles["container--observative"] : ""
          }`}
        >
          {reportType === "observative" ? null : (
            <div className={styles.sec1}>
              <MetricOverview
                description={
                  "Factors that drive an individual's work habits and how they affect professional engagement and performance."
                }
                score={score}
              />
              <div className={styles.sec1__expectation}>
                <p>Expectation metrics:</p>
                <MetricExpectations
                  data={workMotivators.map((item) => ({
                    title: item.expectation,
                    isMatch: item.match,
                  }))}
                />
              </div>
            </div>
          )}

          <div className={styles.qualities}>
            <div className={styles.qualities__nav}>
              {details.map((item, index) => (
                <span
                  role="button"
                  className={active === index ? styles.active : ""}
                  onClick={() => setActive(index)}
                >
                  {item.parameter}
                </span>
              ))}
            </div>
            {details.map((item, index) => (
              <div className={styles.qualities__item}>
                {reportType === "observative" ? null : (
                  <p className={styles.qualities__item__ttl1}>
                    Candidate’s Response:
                    {item.match ? (
                      <span className={styles.qualities__item__ttl1__green}>
                        Match <TickIconCircleFill />
                      </span>
                    ) : (
                      <span className={styles.qualities__item__ttl1__red}>
                        Conflict <CloseIconCircleFIll />
                      </span>
                    )}
                  </p>
                )}

                <p
                  className={`${styles.qualities__item__ttl2} ${
                    styles[
                      `qualities__item__ttl2--${item.match ? "green" : "red"}`
                    ]
                  }`}
                >
                  {item.parameter}{" "}
                </p>
                <div>
                  <p>{item.description}</p>
                </div>
                <div>
                  <p className={styles.qualities__item__listTtl}>Work Agility</p>
                  <ul>
                    {item.agility?.map((val) => (
                      <li>{val}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <p className={styles.qualities__item__listTtl}>Execution & Collaboration Implications </p>
                  <ul>
                    {item.implications?.map((val) => (
                      <li>{val}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </section>
      </section>
    </>
  );
};

const interpretation = [
  {
    title: "Autonomy",
    description: `Autonomy reflects the desire to have control over one’s work, make independent decisions, and shape tasks and schedules. Individuals motivated by autonomy thrive in environments where they can set their own course and execute with minimal oversight.`,
    agility: [
      "Highly adaptable in unstructured environments and capable of self-direction.",
      "Thrives in roles requiring independent problem-solving and decision-making.",
      "Struggles in environments with rigid rules, excessive supervision, or frequent check-ins.",
    ],
    implications: [
      "Excels in ownership-driven work but may struggle with structured processes and external direction.",
      "Prefers autonomy over teamwork; may resist micromanagement or consensus-driven decision-making.",
      "Can miss valuable input from others, struggle with accountability in team settings, and may need alignment to broader team goals.",
    ],
  },
  {
    title: "Teamwork",
    description:
      "Teamwork signifies a strong inclination to collaborate with others to achieve common goals. These individuals prioritize collective success and are motivated by a sense of shared effort.",
    agility: [
      "Adapts well to collaborative environments and cross-functional projects.",
      "Thrives in structured teams with defined roles and shared goals.",
      "May struggle with independent tasks or roles requiring solitary deep work.",
    ],
    implications: [
      "Effective when working with others but may struggle with solo tasks.",
      "Strengthens team dynamics but may hesitate to challenge ideas for the sake of harmony.",
      "Can be overly reliant on team validation, avoid conflict, and struggle with decision-making without group input.",
    ],
  },
  {
    title: "Dedication to a Cause",
    description:
      "This motivator reflects deep commitment to work that aligns with personal values, creating a sense of purpose beyond routine tasks.",
    agility: [
      "Highly adaptable when the work aligns with their values.",
      "Can pivot effectively if a mission-driven reason exists.",
      "Struggles with motivation when work lacks a strong sense of purpose.",
    ],
    implications: [
      "Performs at peak levels when work aligns with their values but may disengage in misaligned environments.",
      "Inspires teams but may resist practical constraints if they believe they compromise the mission.",
      "Can become frustrated with bureaucracy, struggle with compromise, and may prioritize values over efficiency.",
    ],
  },
  {
    title: "Power",
    description:
      "Power represents the drive to influence decisions, shape outcomes, and take leadership in work settings. Individuals motivated by power thrive in high-responsibility roles.",
    agility: [
      "Highly adaptable in leadership and decision-making roles.",
      "Functions best in environments that allow for influence and strategic control.",
      "Struggles in roles with limited authority or rigid hierarchies.",
    ],
    implications: [
      "Drives results and decision-making but may overstep or struggle with delegation.",
      "Can be highly effective in leadership but may dominate discussions or create tension with authority figures.",
      "May micromanage, resist feedback, or engage in power struggles that disrupt teamwork.",
    ],
  },
  {
    title: "Stability",
    description:
      "Stability represents the preference for a secure, predictable work environment with clear expectations and consistency.",
    agility: [
      "Functions best in structured, predictable work settings.",
      "Adapts well to routine but struggles with sudden changes.",
      "Resistant to environments that require frequent pivots or uncertainty.",
    ],
    implications: [
      "Provides consistency and reliability but may resist innovation or rapid change.",
      "Builds dependable relationships but may struggle with dynamic teams or shifting priorities.",
      "May have difficulty adapting to new work structures, resist experimentation, and require extra support during transitions.",
    ],
  },
  {
    title: "Status",
    description:
      "Status reflects the desire to attain recognition, prestige, and a strong professional reputation within an organization or industry.",
    agility: [
      "Adapts well in competitive, high-performance environments.",
      "Thrives in roles with visible success metrics and opportunities for recognition.",
      "May struggle in low-profile or behind-the-scenes roles.",
    ],
    implications: [
      "Drives excellence but may focus on personal achievements over collective success.",
      "Can inspire teams but may also create competition or status-driven conflicts.",
      "May prioritize visibility over effectiveness, struggle with feedback, or disengage if recognition is not forthcoming.",
    ],
  },

  {
    title: "Recognition",
    description:
      "Recognition represents the desire for acknowledgment and appreciation for one’s contributions and achievements.",
    agility: [
      "Highly motivated in performance-driven environments.",
      "Adapts well in workplaces that provide regular feedback and appreciation.",
      "Struggles in roles where contributions go unnoticed or unappreciated.",
    ],
    implications: [
      "Performs best with positive reinforcement but may become disengaged if efforts are overlooked.",
      "Boosts morale and engagement but may expect frequent validation.",
      "Can become demotivated by lack of acknowledgment, struggle with constructive criticism, or overemphasize external validation.",
    ],
  },
  {
    title: "Mastery",
    description:
      "Mastery represents the drive to continuously improve skills, acquire knowledge, and achieve expertise in a particular field.",
    agility: [
      "Adapts well in learning-driven and evolving work environments.",
      "Thrives in roles with opportunities for growth and specialization.",
      "Struggles itextn stagnant roles or tasks that do not challenge their skills.",
    ],
    implications: [
      "High-quality output but may become frustrated with slow learning curves or lack of development opportunities.",
      "Shares expertise with others but may become impatient with less-skilled colleagues.",
      "Can hyper-focus on personal mastery over team goals, resist generalist roles, or struggle with tasks outside their expertise.",
    ],
  },
  {
    title: "Drive for Financial Gain",
    description:
      "Drive for financial gain reflects motivation to earn a competitive salary, bonuses, and financial rewards as a key driver of performance.",
    agility: [
      "Adapts well in goal-oriented and performance-incentivized environments.",
      "Thrives in roles with clear financial incentives or commission structures.",
      "Struggles in roles with slow financial growth or non-monetary rewards.",
    ],
    implications: [
      "Consistently performs to achieve financial targets but may deprioritize long-term growth for short-term gains.",
      "Works well in goal-driven teams but may become overly competitive or transactional.",
      "Can prioritize financial gain over team dynamics, be resistant to unpaid work (such as mentorship or internal projects), or disengage if compensation does not align with expectations.",
    ],
  },
];

export { WorkMotivators };
