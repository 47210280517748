import { Button, EmptyTable, ProgressBar, ScoreBar } from "components";
import styles from "./styles.module.scss";
import {
  ChevronRightIcon,
  CloseIconCircleFIll,
  EyeCloseIcon,
  EyeOpenIcon,
  TickIcon,
  TickIconCircleFill,
  WarningIcon,
  emptyAssessmentsImg,
} from "assets";
import React, { useState } from "react";
import {
  MetricOverview,
  MetricLegend,
  MetricExpectations,
  MetricHeading,
} from "../components";

export interface WorkQualitiesData {
  title: string;
  characteristics: string[];
  behaviors: string[];
  match: boolean;
  traits?: string[];
  showUpAtWork?: string[];
  execution?: { text: string; type: string }[];
}

export interface WorkQualitiesProps {
  workQualities: WorkQualitiesData[] | undefined;
  score: number | undefined;
  isFullReport: boolean;
  expectation: { title: string; isMatch: boolean }[];
  reportType: string;
}

const WorkQualities: React.FC<WorkQualitiesProps> = ({
  isFullReport,
  score,
  workQualities,
  expectation,
  reportType,
}) => {
  const [moreInsights, setMoreInsights] = useState(false);

  if (!workQualities || score === undefined)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );

  const qualities = workQualities.map((quality) => ({
    ...quality,
    ...interpretation.find(
      (item) =>
        quality.title.toLowerCase().trim() === item.title.toLowerCase().trim()
    ),
  }));

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Workplace Qualities" /> : ""}
        <Button
          className={styles.moreInsightsBtn}
          onClick={() => setMoreInsights((prev) => !prev)}
          type="transparent"
        >
          {moreInsights ? <EyeCloseIcon /> : <EyeOpenIcon />}
          {!moreInsights ? "See" : "Hide"} more insights
        </Button>
        <section
          className={`${styles.container} ${
            reportType === "observative" ? styles["container--observative"] : ""
          }`}
        >
          {reportType === "observative" ? null : (
            <div className={styles.sec1}>
              <MetricOverview
                description={
                  "Individuals' traits and attributes that contribute to a positive and thriving work environment."
                }
                score={score}
              />
              <div className={styles.sec1__expectation}>
                <p>Expectation metrics:</p>
                <MetricExpectations data={expectation} />
              </div>
            </div>
          )}
          <div>
            {qualities.map((quality) => (
              <Quality
                {...quality}
                reportType={reportType}
                moreInsights={moreInsights}
              />
            ))}
          </div>
        </section>
      </section>
    </>
  );
};

interface QualitiesProps extends WorkQualitiesData {
  reportType: string;
  moreInsights: boolean;
}
const Quality: React.FC<QualitiesProps> = ({
  match,
  characteristics,
  behaviors,
  title,
  reportType,
  traits,
  showUpAtWork,
  execution,
  moreInsights,
}) => {
  const [show, setShow] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 600 ? true : false);

  const screenSizeUpdate = () => {
    if (window.innerWidth <= 600) {
      setMobile(true);
    } else if (window.innerWidth > 600) {
      setMobile(false);
    }
  };

  window.addEventListener("resize", screenSizeUpdate);

  return (
    <>
      <p className={styles.ttl}>
        {title}
        <span className={match ? styles.green : styles.red}>
          {reportType === "observative" ? null : match ? (
            <>
              Match <TickIconCircleFill />{" "}
            </>
          ) : (
            <>
              Conflict <CloseIconCircleFIll />
            </>
          )}

          <ChevronRightIcon
            className={`${styles.chevron} ${show ? styles.rotate : ""}`}
            role="button"
            onClick={() => setShow((prev) => !prev)}
          />
        </span>
      </p>
      {show || !mobile ? (
        <div className={styles.qualities}>
          <div className={styles.qualities__item}>
            <p className={styles.qualities__item__ttl}>Key Traits </p>
            <ul>
              {traits?.map((val) => (
                <li>{val}</li>
              ))}
            </ul>
          </div>
          <div className={styles.qualities__item}>
            <p className={styles.qualities__item__ttl}>
              How It Shows Up at Work{" "}
            </p>
            <ul>
              {showUpAtWork?.map((val) => (
                <li>{val}</li>
              ))}
            </ul>
          </div>
          {moreInsights ? (
            <div className={styles.qualities__item}>
              <p className={styles.qualities__item__ttl}>
                How This Affects Execution{" "}
              </p>
              <ul className={styles.noBullet}>
                {execution?.map((val) => (
                  <li>
                    {" "}
                    {val.type === "tick" ? <TickIcon /> : <WarningIcon />}{" "}
                    {val.text}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const interpretation = [
  {
    title: "Principled",
    traits: [
      "Purposeful, responsible, and self-disciplined.",
      "Naturally focused on identifying and correcting errors.",
      "Strong inner critic drives a commitment to high standards.",
    ],
    showUpAtWork: [
      " Works best within structured environments and goal-oriented tasks.",
      "Holds themselves and others to high standards.",
      "Readily shares opinions, often providing constructive (or critical) feedback.",
    ],
    execution: [
      {
        text: "Ensures precision and consistency, maintaining high-quality work",
        type: "tick",
      },
      {
        text: "Helps prevent mistakes by proactively identifying and correcting errors.",
        type: "tick",
      },
      {
        text: "May slow down execution due to perfectionism or overanalyzing details.",
        type: "warning",
      },
      {
        text: "Can struggle with flexibility, resisting changes that deviate from structure.",
        type: "warning",
      },
    ],
  },
  {
    title: "Supportive",
    traits: [
      "Warm, approachable, and attuned to the needs of others.",
      "Adapts behavior to maintain relationships and foster harmony.",
      "Struggles to voice personal needs but is deeply committed to team success.",
    ],
    showUpAtWork: [
      "Focuses on building strong relationships and gaining respect from key figures.",
      "Motivated by approval and encouragement; discouraged by criticism.",
      "Values emotional connection and meaning in work, not just tasks.",
    ],
    execution: [
      {
        text: "Strengthens team cohesion and collaboration, making work environments more inclusive.",
        type: "tick",
      },
      {
        text: "Works hard to support collective goals, ensuring others feel valued.",
        type: "tick",
      },
      {
        text: "May avoid difficult conversations or conflict, leading to unresolved issues.",
        type: "warning",
      },
      {
        text: "Can struggle with prioritizing personal tasks over helping others, delaying execution.",
        type: "warning",
      },
    ],
  },
  {
    title: "Competitive",
    traits: [
      "Highly ambitious, driven by success and achievement.",
      "Focused on results, with little patience for inefficiency.",
      "Often views emotions as secondary to performance.",
    ],
    showUpAtWork: [
      "Operates with a strong sense of urgency, striving to outperform peers.",
      "Thrives in high-pressure, fast-paced environments.",
      "Prefers positions of influence, seeking recognition for accomplishments.",
    ],
    execution: [
      {
        text: "Moves fast and prioritizes efficiency, ensuring rapid progress toward goals.",
        type: "tick",
      },
      {
        text: "Pushes teams to perform at a high level and stay outcome-focused.",
        type: "tick",
      },
      {
        text: "Can be impatient, overlooking details or dismissing processes perceived as slow.",
        type: "warning",
      },
      {
        text: "May struggle with collaboration if others do not match their pace or ambition.",
        type: "warning",
      },
    ],
  },
  {
    title: "Idealistic",
    traits: [
      "Creative, empathetic, and deeply value-driven.",
      "Seeks to make a unique impact through meaningful work.",
      "Resists rigid evaluations and craves recognition for individuality.",
    ],
    showUpAtWork: [
      "Brings fresh, unconventional ideas and challenges the status quo.",
      "Can work deeply on complex problems, especially those with emotional depth.",
      "May struggle with tasks that feel mundane or uninspiring.",
    ],
    execution: [
      {
        text: "Excels at conceptualizing bold, original ideas that can drive innovation.",
        type: "tick",
      },
      {
        text: "Can resolve emotional or interpersonal challenges with deep understanding.",
        type: "tick",
      },
      {
        text: "May lose motivation when work lacks creative freedom or personal significance.",
        type: "warning",
      },
      {
        text: "Can be distracted by grand visions, struggling to execute routine tasks.",
        type: "warning",
      },
    ],
  },

  {
    title: "Innovative",
    traits: [
      "Curious, analytical, and constantly seeking knowledge.",
      "Observes more than participates, preferring deep thinking over immediate action.",
      "Tends to be private and independent in work style.",
    ],
    showUpAtWork: [
      "Excels at identifying patterns, making predictions, and solving complex problems.",
      "Enjoys working alone with minimal supervision.",
      "Prefers intellectual challenges over routine, repetitive tasks.",
    ],
    execution: [
      {
        text: "Generates insightful, well-reasoned solutions to complex problems.",
        type: "tick",
      },
      {
        text: "Works well in research, strategy, or technical roles requiring deep analysis.",
        type: "tick",
      },
      {
        text: "May overanalyze, delaying decision-making and slowing execution.",
        type: "warning",
      },
      {
        text: "Can struggle with team collaboration, preferring to work independently.",
        type: "warning",
      },
    ],
  },
  {
    title: "Responsible",
    traits: [
      "Reliable, hardworking, and highly conscientious.",
      "Prone to questioning authority and thinking critically about situations.",
      "Worries about potential risks, often planning for worst-case scenarios.",
    ],
    showUpAtWork: [
      "Acts as a troubleshooting expert, spotting problems before they arise.",
      "Comfortable analyzing information and making data-driven decisions.",
      "Follows rules carefully and expects others to do the same.",
    ],
    execution: [
      {
        text: "Anticipates challenges, reducing risks and ensuring smooth operations.",
        type: "tick",
      },
      {
        text: "Takes ownership of responsibilities and follows through on commitments.",
        type: "tick",
      },
      {
        text: "May delay action due to overthinking or fear of making mistakes.",
        type: "warning",
      },
      {
        text: "Can resist quick decisions or changes without thorough analysis.",
        type: "warning",
      },
    ],
  },
  {
    title: "adventurous",
    traits: [
      "Energetic, optimistic, and driven by new experiences.",
      "Prefers variety and dislikes feeling constrained.",
      "Can be easily distracted or overcommitted.",
    ],
    showUpAtWork: [
      "Enthusiastic and uplifting, helping to energize teams.",
      "Enjoys brainstorming and launching new projects.",
      "Tends to lose interest in repetitive or routine work.",
    ],
    execution: [
      {
        text: "Brings high energy and momentum, motivating teams to take action.",
        type: "tick",
      },
      {
        text: "Generates creative ideas and thrives in dynamic, fast-paced environments.",
        type: "tick",
      },
      {
        text: "May struggle with follow-through, leaving projects incomplete.",
        type: "warning",
      },
      {
        text: "Can become overextended, juggling too many tasks at once.",
        type: "warning",
      },
    ],
  },
  {
    title: "Assertive",
    traits: [
      "Bold, independent, and action-oriented.",
      "Highly opinionated and unafraid to challenge others.",
      "Naturally seeks control and influence.",
    ],
    showUpAtWork: [
      "Comfortable taking charge and making decisions quickly.",
      "Prefers a leadership role with minimal oversight.",
      "Prioritizes results over process, often pushing for immediate action.",
    ],
    execution: [
      {
        text: "Moves swiftly and decisively, ensuring tasks are completed efficiently.",
        type: "tick",
      },
      {
        text: "Excels at leading initiatives, especially in high-stakes situations.",
        type: "tick",
      },
      {
        text: "Can be overly forceful, leading to friction with colleagues.",
        type: "warning",
      },
      {
        text: "May overlook details or dismiss input that slows down progress.",
        type: "warning",
      },
    ],
  },
  {
    title: "Adaptive",
    traits: [
      "Easy-going, accepting, and conflict-averse.",
      "Prioritizes maintaining harmony in teams.",
      "May struggle with direct confrontation or asserting personal needs.",
    ],
    showUpAtWork: [
      "Acts as a natural mediator, resolving conflicts and smoothing over tension.",
      "Willing to assist wherever needed, making them highly dependable.",
      "Works best when given time to focus independently.",
    ],
    execution: [
      {
        text: "Fosters teamwork and collaboration, creating a positive work environment.",
        type: "tick",
      },
      {
        text: "Can seamlessly adapt to different roles and responsibilities as needed.",
        type: "tick",
      },
      {
        text: "May struggle to take initiative or push back when necessary.",
        type: "warning",
      },
      {
        text: "Can get pulled in multiple directions, slowing down personal task completion.",
        type: "warning",
      },
    ],
  },
];

export { WorkQualities };
