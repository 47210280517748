import { Comparisons, EmptyTable, RatingBar, ScoreBar } from "components";
import styles from "./styles.module.scss";
import { emptyAssessmentsImg } from "assets";
import {
  MetricOverview,
  MetricExpectations,
  MetricHeading,
} from "../components";
import { useEffect, useState } from "react";

interface PersonalityCoreData {
  title: string;
  rating: number;
  label1: string;
  label2: string;
}

interface PersonalityData {
  title: string;
  strengths: { title: string; content: string }[];
  growAreas: { title: string; content: string }[];
}

export interface PersonalityResultData {
  data: PersonalityCoreData[];
  info: PersonalityData;
  expectation: PersonalityData;
}

export interface PersonalityProps {
  score: number | undefined;
  personality: PersonalityResultData | undefined;
  isFullReport: boolean;
  reportType: string;
}

const Personality: React.FC<PersonalityProps> = ({
  isFullReport,
  score,
  personality,
  reportType,
}) => {
  const [active, setActive] = useState<PersonalityData>();

  useEffect(() => {
    setActive(personality?.info);
  }, [personality]);

  if (!personality || score === undefined)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );

  const getInfo = (interpretation, score, area) => {
    return {
      points: interpretation
        .find((item) => score >= item.min && score <= item.max)
        ?.dimensions.find(
          (dimension) => dimension.title.toLowerCase() === area.toLowerCase()
        )?.points,
    };
  };

  const match = {
    "interacting with people": interactingInterpretation,
    "problem solving": problemInterpretation,
    "processing information": processingingInterpretation,
    "decision making": decisionInterpretation,
  };

  const personalityData = personality.data.map((item) => ({
    ...item,
    ...getInfo(match[item.title.toLowerCase()], item.rating, item.label1),
  }));

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Personality" /> : ""}
        <section
          className={`${styles.container} ${
            reportType === "observative" ? styles["container--observative"] : ""
          }`}
        >
          {reportType === "observative" ? null : (
            <div className={styles.sec1}>
              <MetricOverview
                description={
                  "Gain insights into individual character traits and their impact on various aspects of work."
                }
                score={score}
              />
              <div className={styles.sec1__legend}>
                <div
                  role="button"
                  style={{ marginBottom: "1.5rem" }}
                  className={styles.sec1__expectation}
                  onClick={() => setActive(personality.expectation)}
                >
                  <p>Expected personality type:</p>
                  <MetricExpectations
                    data={[{ title: personality.expectation.title }]}
                  />
                </div>
                <div
                  role="button"
                  onClick={() => setActive(personality.info)}
                  className={styles.sec1__expectation}
                >
                  <p>Candidates's personality type:</p>
                  <MetricExpectations
                    data={[{ title: personality.info.title }]}
                  />
                </div>
              </div>
            </div>
          )}
          <Comparisons
            data={personalityData.map((item) => ({
              leftLabel: item.label1,
              rightLabel: item.label2,
              result: item.rating,
              title: item.title,
              expectationStr: `${100 - item.rating}%`,
              scale: 100,
              body: (
                <ul className={styles.points}>
                  {item.points?.map((point) => (
                    <li>{point}</li>
                  ))}
                </ul>
              ),
            }))}
            reportType={reportType}
          />
        </section>
        <div className={styles.mobile__legend}>
          <div
            role="button"
            style={{ marginBottom: "1.5rem" }}
            className={styles.sec1__expectation}
            onClick={() => setActive(personality.expectation)}
          >
            <p>Expected personality type:</p>
            <MetricExpectations
              data={[{ title: personality.expectation.title }]}
            />
          </div>
          <div
            role="button"
            onClick={() => setActive(personality.info)}
            className={styles.sec1__expectation}
          >
            <p>Candidates's personality type:</p>
            <MetricExpectations data={[{ title: personality.info.title }]} />
          </div>
        </div>
        {active ? (
          <div className={styles.details}>
            <p className={styles.details__ttl}>{active.title}</p>
            <div>
              <ol>
                <p>Strengths</p>
                {active.strengths.map((strength) => (
                  <li>
                    <b>{strength.title}:</b>
                    {strength.content}
                  </li>
                ))}
              </ol>
              <ol>
                <p>Growth Areas</p>
                {active.growAreas.map((strength) => (
                  <li>
                    <b>{strength.title}:</b>
                    {strength.content}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export { Personality };

const interactingInterpretation = [
  {
    max: 100,
    min: 90,
    title: `Highly Dominant Personality Traits`,
    dimensions: [
      {
        title: "Social Extrovert",
        points: [
          "Thrives in social environments, excels at networking and group activities, center of attention, energized by interaction.",
          "Highly energetic, strong communicator, great at relationship-building, inspires others.",
          "Struggles with solo work, may overpower quieter voices.",
        ],
      },
      {
        title: "Independent Extrovert",
        points: [
          "Enjoys social interactions but values autonomy; excels in leadership roles.",
          "Strong leadership, skilled in networking, balances collaboration with independence.",
          "Frustrated by highly collaborative environments, prefers autonomy.",
        ],
      },
      {
        title: "Social Introvert",
        points: [
          "Prefers minimal social engagement; excels in independent, focused work.",
          "Independent, strong in one-on-one interactions, introspective.",
          "Drained by team-driven environments, prefers background roles.",
        ],
      },
      {
        title: "Independent Introvert",
        points: [
          " Highly self-reliant, excels in autonomous work with minimal social interaction.",
          "Self-sufficient, strong decision-making, excels in deep work.",
          "Struggles in collaborative environments, may appear withdrawn.",
        ],
      },
    ],
  },
  {
    max: 89,
    min: 70,
    title: "Predominantly Showing Strong Elements",
    dimensions: [
      {
        title: "Social Extrovert",
        points: [
          "Outgoing but flexible, comfortable in both social and independent settings.",
          "Strong interpersonal skills, comfortable with both solo and team work.",
          "May feel drained without social engagement, prefers group dynamics.",
        ],
      },
      {
        title: "Independent Extrovert",
        points: [
          "Enjoys social interaction but retains control over tasks; balances collaboration with autonomy.",
          "Confident in leadership, effective communicator, balances team input and independence.",
          "Struggles with constant collaboration or shared decision-making.",
        ],
      },
      {
        title: "Social Introvert",
        points: [
          "Engages socially when needed, values autonomy, prefers smaller groups.",
          "Strong in focused work, forms meaningful relationships, comfortable in smaller settings.",
          "Drained by large teams or constant interaction.",
        ],
      },
      {
        title: "Independent Introvert",
        points: [
          "Prioritizes independence, open to collaboration in small, purposeful settings.",
          "Strong self-reliance, deep focus, engages in meaningful group discussions.",
          "Feels disconnected in large teams or forced social settings.",
        ],
      },
    ],
  },
  {
    max: 69,
    min: 50,
    title: "Balanced Tendencies Across the Spectrum",
    dimensions: [
      {
        title: "Social Extrovert",
        points: [
          " Enjoys social interaction but adaptable to both solo and team work.",
          "Collaborative, strong interpersonal skills, balances group and solo tasks.",
          "Struggles to initiate relationships or engage in constant social situations.",
        ],
      },
      {
        title: "Independent Extrovert",
        points: [
          "Prefers autonomy but engages socially as needed, can feel constrained in highly collaborative settings.",
          "Confident in social settings, able to work independently and lead effectively.",
          "Prefers independence over constant collaboration or shared decision-making.",
        ],
      },
      {
        title: "Social Introvert",
        points: [
          "More open to social interaction, prefers small, controlled environments.",
          "Effective in focused work, capable in smaller groups, forms meaningful relationships.",
          "Struggles in larger, dynamic teams or constant social engagement.",
        ],
      },
      {
        title: "Independent Introvert",
        points: [
          "Prioritizes autonomy but can engage occasionally when needed; prefers solitude.",
          "Self-reliant, excels in solo work, capable in small groups.",
          "Finds constant interaction or collaboration difficult, may withdraw in larger teams.",
        ],
      },
    ],
  },
];

const problemInterpretation = [
  {
    max: 100,
    min: 90,
    title: `Highly Dominant Personality Traits`,
    dimensions: [
      {
        title: "Practical",
        points: [
          "Strong focus on tangible, realistic solutions; tends to approach challenges with a straightforward, methodical mindset.",
          "Efficient, results-driven, prefers practical approaches with clear, actionable outcomes.",
          "May struggle in situations requiring unconventional or abstract thinking, limited by established methods.",
        ],
      },
      {
        title: "Creative",
        points: [
          "Thinks outside the box, uses innovative and imaginative methods to address challenges; open to new, unconventional solutions.",
          "Highly adaptable, excellent at generating new ideas, capable of solving complex, ambiguous problems.",
          "May overlook practical aspects or fail to consider more grounded solutions, potential difficulty with implementation.",
        ],
      },
    ],
  },
  {
    max: 89,
    min: 70,
    title: "Predominantly Showing Strong Elements",
    dimensions: [
      {
        title: "Practical",
        points: [
          "Effective at identifying realistic solutions but open to exploring alternative approaches when necessary.",
          "Reliable and pragmatic, good at implementing solutions in familiar situations.",
          "May resist more creative or unconventional problem-solving approaches.",
        ],
      },
      {
        title: "Creative",
        points: [
          "Still uses creative solutions but balances them with practical considerations. Can think imaginatively but values feasibility.",
          "Capable of generating innovative solutions, adaptable in dynamic situations.",
          "Tends to overcomplicate problems, may struggle to ground solutions in reality or scale them effectively.",
        ],
      },
    ],
  },
  {
    max: 69,
    min: 50,
    title: "Balanced Tendencies Across the Spectrum",
    dimensions: [
      {
        title: "Practical",
        points: [
          "Strikes a balance between practical and creative methods; approaches problems with a mix of realism and flexibility.",
          "Can shift between practical and creative thinking as needed, versatile in addressing a wide range of challenges.",
          "May struggle to identify the most efficient solution, leaning too much towards one approach in different situations.",
        ],
      },
      {
        title: "Creative",
        points: [
          "Open to creativity but may prefer practical solutions for easier implementation. Tries to balance imagination with realistic outcomes.",
          "Able to come up with innovative ideas while staying grounded in the situation.",
          "May find it difficult to consistently apply creative solutions or get lost in ideation without tangible outcomes.",
        ],
      },
    ],
  },
];

const processingingInterpretation = [
  {
    max: 100,
    min: 90,
    title: `Highly Dominant Personality Traits`,
    dimensions: [
      {
        title: "Logical",
        points: [
          "Strong reliance on structured analysis and reasoning to interpret information and form conclusions.",
          "Highly organized, detail-oriented, excels in data-driven decision-making, and clear, methodical thinking.",
          "May struggle with emotional aspects of situations or interpreting ambiguous or subjective data.",
        ],
      },
      {
        title: "Emotional",
        points: [
          "Uses intuition and feelings to process information, often guided by personal experiences and emotional responses.",
          "Excellent at understanding human dynamics, empathetic, able to sense underlying emotional cues in complex situations.",
          "May overlook logical facts or struggle to separate emotions from objective analysis.",
        ],
      },
    ],
  },
  {
    max: 89,
    min: 70,
    title: "Predominantly Showing Strong Elements",
    dimensions: [
      {
        title: "Logical",
        points: [
          "Often relies on logical thinking but can incorporate personal feelings or experiences into decision-making when necessary.",
          "Good balance between reason and emotion, logical in familiar or analytical situations.",
          "May become too analytical or detached when emotional sensitivity is required.",
        ],
      },
      {
        title: "Emotional",
        points: [
          "Primarily emotional in processing information, but able to incorporate logic when the situation calls for it.",
          "Skilled at understanding complex emotional landscapes, intuitive decision-making, and interpersonal communication.",
          "May let emotions cloud judgment in highly logical or structured scenarios.",
        ],
      },
    ],
  },
  {
    max: 69,
    min: 50,
    title: "Balanced Tendencies Across the Spectrum",
    dimensions: [
      {
        title: "Logical",
        points: [
          "Implications: Balances logical analysis with emotional awareness, using both when interpreting information.",
          "Strengths: Flexible, able to use logic or intuition depending on the situation.",
          "Challenges: May find it difficult to settle on one approach, potentially overanalyzing or becoming too emotionally invested.",
        ],
      },
      {
        title: "Emotional",
        points: [
          "Processes information using both emotions and logic; adaptable, relying on whichever is more applicable.",
          "Well-rounded, able to see different perspectives.",
          "May struggle to find the right balance, potentially relying on emotions in overly structured environments or vice versa.",
        ],
      },
    ],
  },
];

const decisionInterpretation = [
  {
    max: 100,
    min: 90,
    title: `Highly Dominant Personality Traits`,
    dimensions: [
      {
        title: "Systematic",
        points: [
          "Methodical and organized, always weighing all factors before making decisions.",
          "Thorough, well-prepared, ensures informed decisions with minimal risks.",
          "May overanalyze or delay decisions, particularly when quick action is required.",
        ],
      },
      {
        title: "Spontaneous",
        points: [
          "Prefers to make decisions quickly based on intuition, often without extensive deliberation or planning.",
          "Decisive, agile, adaptable in dynamic environments.",
          "May overlook important details or fail to consider all perspectives, leading to rushed or less thorough decisions.",
        ],
      },
    ],
  },
  {
    max: 89,
    min: 70,
    title: "Predominantly Showing Strong Elements",
    dimensions: [
      {
        title: "Systematic",
        points: [
          "Tends to approach decisions methodically but can make quick decisions when necessary.",
          "Balances analysis and action, organized but capable of responding rapidly when required.",
          "May struggle in highly unpredictable situations where fast, intuitive decisions are needed.",
        ],
      },
      {
        title: "Spontaneous",
        points: [
          "Leans towards intuitive decision-making but is capable of taking a more structured approach when the situation demands it.",
          "Quick to act, flexible, thrives in fast-paced environments.",
          "Can lack thorough planning, which may result in missed opportunities or suboptimal choices in complex scenarios.",
        ],
      },
    ],
  },
  {
    max: 69,
    min: 50,
    title: "Balanced Tendencies Across the Spectrum",
    dimensions: [
      {
        title: "Systematic",
        points: [
          "Capable of both methodical and spontaneous decision-making, adjusting based on the context.",
          "Adaptable, able to switch between structured and quick thinking based on the situation.",
          "May struggle with decision fatigue, unsure of whether to follow a more planned or instinctual path.",
        ],
      },
      {
        title: "Spontaneous",
        points: [
          "Mostly relies on intuition but can engage in more systematic decision-making when needed.",
          "Fast, flexible, able to handle uncertainty and change.",
          "Potential for inconsistency in decision-making, especially in situations where methodical planning is critical.",
        ],
      },
    ],
  },
];
