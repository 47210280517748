import {
  Button,
  ComparisonData,
  Comparisons,
  EmptyTable,
  ScoreBar,
} from "components";
import styles from "./styles.module.scss";
import { useState } from "react";
import { emptyAssessmentsImg, EyeCloseIcon, EyeOpenIcon } from "assets";
import { MetricHeading, MetricLegend, MetricOverview } from "../components";

export interface WorkStyleData {
  keyQuality: string;
  goodAt: string[];
  poorAt: string[];
}

export interface WorkStyleResultData {
  prioritizing: {
    expectation: number;
    result: number;
    keyQuality: string;
    goodAt: string[];
    poorAt: string[];
  };
  visualizing: {
    expectation: number;
    result: number;
    keyQuality: string;
    goodAt: string[];
    poorAt: string[];
  };
  planning: {
    expectation: number;
    result: number;
    keyQuality: string;
    goodAt: string[];
    poorAt: string[];
  };
  coordinating: {
    expectation: number;
    result: number;
    keyQuality: string;
    goodAt: string[];
    poorAt: string[];
  };
}

export interface WorkStyleProps {
  isFullReport: boolean;
  workStyle: WorkStyleResultData | undefined;
  score: number | undefined;
  reportType: string;
}

const WorkStyle: React.FC<WorkStyleProps> = ({
  isFullReport,
  workStyle,
  score,
  reportType,
}) => {
  const [active, setActive] = useState(0);
  const [mobile, setMobile] = useState(window.innerWidth <= 900);
  const [moreInsights, setMoreInsights] = useState(false);

  const screenSizeUpdate = () => {
    setMobile(window.innerWidth <= 900);
  };

  window.addEventListener("resize", screenSizeUpdate);

  if (!workStyle || score === undefined)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );

  const getInfo = (interpretation, score) => {
    return {
      impact: interpretation.find(
        (item) => score >= item.min && score <= item.max
      )?.impact,
      implication: interpretation.find(
        (item) => score >= item.min && score <= item.max
      )?.impact,
      growthAreas: interpretation.find(
        (item) => score >= item.min && score <= item.max
      )?.growthAreas,
    };
  };

  const data: ComparisonData[] = [
    {
      title: "Prioritizing",
      expectationStr: `${workStyle.prioritizing.expectation}%`,
      scale: workStyle.prioritizing.expectation,
      result: workStyle.prioritizing.result,
      body: (
        <ExtraInfo
          {...getInfo(
            prioritizingInterpretation,
            workStyle.prioritizing.result
          )}
          moreInsights={moreInsights}
        />
      ),
    },
    {
      title: "Visualizing",
      expectationStr: `${workStyle.visualizing.expectation}%`,
      scale: workStyle.visualizing.expectation,
      result: workStyle.visualizing.result,
      body: (
        <ExtraInfo
          {...getInfo(visualisingInterpretation, workStyle.visualizing.result)}
          moreInsights={moreInsights}
        />
      ),
    },
    {
      title: "Planning",
      expectationStr: `${workStyle.planning.expectation}%`,
      scale: workStyle.planning.expectation,
      result: workStyle.planning.result,
      body: (
        <ExtraInfo
          {...getInfo(planningInterpretation, workStyle.planning.result)}
          moreInsights={moreInsights}
        />
      ),
    },
    {
      title: "Coordinating",
      expectationStr: `${workStyle.coordinating.expectation}%`,
      scale: workStyle.coordinating.expectation,
      result: workStyle.coordinating.result,
      body: (
        <ExtraInfo
          {...getInfo(
            coordinatingInterpretation,
            workStyle.coordinating.result
          )}
          moreInsights={moreInsights}
        />
      ),
    },
  ];

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Work Style" /> : ""}
        <Button
          className={styles.moreInsightsBtn}
          onClick={() => setMoreInsights((prev) => !prev)}
          type="transparent"
        >
          {moreInsights ? <EyeCloseIcon /> : <EyeOpenIcon />}
          {!moreInsights ? "See" : "Hide"} more insights
        </Button>
        <section
          className={`${styles.container} ${
            reportType === "observative" ? styles["container--observative"] : ""
          }`}
        >
          {reportType === "observative" ? null : (
            <div className={styles.sec1}>
              <MetricOverview
                description={
                  "Individuals' approach, preferences, and methodology in carrying out tasks and responsibilities."
                }
                score={score}
              />
              <MetricLegend />
            </div>
          )}
          <Comparisons data={data} reportType={reportType} />
        </section>
      </section>
    </>
  );
};

const Quality: React.FC<WorkStyleData> = ({ keyQuality, goodAt, poorAt }) => {
  return (
    <div className={styles.qualities__item}>
      <div>
        <p className={styles.qualities__item__ttl}>Key Quality </p>
        <p>{keyQuality}</p>
      </div>
      <div>
        <p className={styles.qualities__item__ttl}>Good at </p>
        <ul>
          {goodAt.map((val) => (
            <li>{val}</li>
          ))}
        </ul>
      </div>
      <div>
        <p className={styles.qualities__item__ttl}>Poor at </p>
        <ul>
          {poorAt.map((val) => (
            <li>{val}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

interface WorkStyleExtra {
  implication: string;
  impact: string;
  growthAreas: string;
  moreInsights: boolean;
}
const ExtraInfo: React.FC<WorkStyleExtra> = ({
  implication,
  impact,
  growthAreas,
  moreInsights,
}) => {
  return (
    <div className={styles.qualities__item}>
      <div>
        <p className={styles.qualities__item__ttl}>Implication </p>
        <p>{implication}</p>
      </div>
      <div>
        <p className={styles.qualities__item__ttl}>Impact on Work </p>
        <p>{impact}</p>
      </div>
      {moreInsights ? (
        <div>
          <p className={styles.qualities__item__ttl}>
            Opportunities for Growth{" "}
          </p>
          <p>{growthAreas}</p>
        </div>
      ) : null}
    </div>
  );
};

const prioritizingInterpretation = [
  {
    max: 100,
    min: 90,
    title: `Exceptional`,
    implication: `Highly focused on execution, ensuring tasks are completed with minimal distractions or delays. Works independently, adapts quickly, and thrives in high-pressure environments. May struggle with collaboration but excels in delivering results.`,
    impact: `Performs best in roles requiring autonomous execution, quick problem-solving, and decisive action. Might need structured communication touchpoints to ensure alignment with teams.`,
    growthAreas: `Strengthening collaboration and communication skills with others can enhance leadership potential. Developing awareness of blind spots and learning to integrate feedback can refine execution quality.`,
  },
  {
    max: 89,
    min: 70,
    title: "Proficient",
    implication: `Strong ability to stay on task and execute efficiently, balancing speed and quality. Works well independently but may occasionally miss opportunities for collaboration or process improvements.`,
    impact: `Reliable in delivering results but could enhance effectiveness by integrating better communication and team alignment strategies.`,
    growthAreas: `Improving teamwork skills and learning structured execution frameworks can help scale efficiency. Seeking coaching and mentorship can also refine decision-making under pressure.`,
  },
  {
    max: 69,
    min: 50,
    title: "Developing",
    implication: `Has the capacity for focused execution but may struggle with consistency. Can complete tasks independently but might need guidance in prioritization and agility.`,
    impact: `Benefits from structured workflows and clear directives. Development in process discipline and collaboration would enhance overall performance.`,
    growthAreas: `Practicing self-management techniques, improving prioritization, and seeking regular feedback can strengthen execution reliability.`,
  },
  {
    max: 49,
    min: 0,
    title: "Limited",
    implication: `Struggles with maintaining execution focus, possibly getting distracted or losing momentum. Likely to need external motivation or structured support.`,
    impact: `Works best in environments with strong oversight and clear accountability frameworks. May need to develop habits that reinforce task completion.`,
    growthAreas: `Using time management techniques, breaking work into manageable steps, and setting accountability checkpoints can boost execution capability.`,
  },
];

const visualisingInterpretation = [
  {
    max: 100,
    min: 90,
    title: "Exceptional",
    implication: `Strong ability to challenge existing ideas and generate innovative solutions. Excels in strategic thinking and connecting complex ideas. Might struggle with execution-heavy tasks.`,
    impact: `Ideal for strategy, ideation, and problem-solving roles. Works best when paired with execution-focused individuals to bring ideas to life.`,
    growthAreas: `Learning execution frameworks, delegating effectively, and setting structured follow-through checkpoints can help turn ideas into tangible outcomes.`,
  },
  {
    max: 89,
    min: 70,
    title: "Proficient",
    implication: `A natural problem solver who generates valuable insights and alternative approaches. May struggle with execution but can adapt when needed.`,
    impact: `Contributes effectively in brainstorming and planning but benefits from support in task management and follow-through.`,
    growthAreas: `Developing structured execution habits and prioritization techniques can help bring ideas to life more effectively.`,
  },
  {
    max: 69,
    min: 50,
    title: "Developing",
    implication: `Has creative and strategic thinking potential but may not consistently translate ideas into actionable steps. Needs guidance to refine ideation into practical solutions.`,
    impact: `Works best with structured problem-solving frameworks. Could improve by strengthening execution discipline.`,
    growthAreas: `Learning project management basics, working closely with execution-driven colleagues, and using structured ideation tools can enhance effectiveness.`,
  },
  {
    max: 49,
    min: 0,
    title: "Limited",
    implication: `Struggles with abstract thinking and generating strategic insights. Likely to be more comfortable with clear, structured tasks rather than conceptual work.`,
    impact: `May need structured brainstorming exercises or exposure to strategic thinking to develop visualization skills.`,
    growthAreas: `Engaging in critical thinking exercises, working with mentors, and seeking exposure to different industries can help expand strategic thinking ability.`,
  },
];

const planningInterpretation = [
  {
    max: 100,
    min: 90,
    title: "Exceptional",
    implication: `Highly skilled in structuring tasks, detailing workflows, and ensuring seamless execution. Strong risk management abilities but may resist flexibility and change.`,
    impact: `Excels in operations, project management, and structured environments. May need to develop adaptability to handle rapid shifts.`,
    growthAreas: `Practicing agility by working in dynamic settings, embracing adaptive planning techniques, and refining decision-making under uncertainty can help.`,
  },
  {
    max: 89,
    min: 70,
    title: "Proficient",
    implication: `Strong at structuring work and anticipating potential issues but may occasionally struggle with adjusting plans dynamically.`,
    impact: `Effective in planning roles but benefits from building agility to accommodate evolving situations.`,
    growthAreas: `Improving real-time problem-solving and scenario planning skills can increase adaptability.`,
  },
  {
    max: 69,
    min: 50,
    title: "Developing",
    implication: `Has a basic ability to structure tasks but might overlook critical details or struggle with maintaining comprehensive plans.`,
    impact: `Works best in roles with clear guidelines. Would benefit from mentorship in refining planning and risk assessment skills.`,
    growthAreas: `Learning structured planning methodologies, refining attention to detail, and practicing proactive problem-solving can strengthen planning ability.`,
  },
  {
    max: 49,
    min: 0,
    title: "Limited",
    implication: `Struggles with organizing work, sequencing tasks, or anticipating potential roadblocks. Likely to rely on external support for structuring tasks`,
    impact: `Performs best in execution-driven roles where detailed planning is handled by others. May need tools or training to improve organization skills.`,
    growthAreas: `Using workflow management tools, seeking mentorship, and practicing structured task planning can enhance ability in this area.`,
  },
];

const coordinatingInterpretation = [
  {
    max: 100,
    min: 90,
    title: "Exceptional",
    implication: `Highly people-focused, excelling in communication, teamwork, and balancing multiple perspectives. Strong ability to align teams and facilitate collaboration.`,
    impact: `Ideal for leadership, team-building, and cross-functional roles. May need to work on time and expectation management to enhance efficiency.`,
    growthAreas: `Strengthening delegation, improving time management, and refining strategic communication skills can elevate leadership potential.`,
  },
  {
    max: 89,
    min: 70,
    title: "Proficient",
    implication: `Effective collaborator and communicator who thrives in team-oriented environments. May sometimes struggle with time or expectation management.`,
    impact: `Contributes positively to team dynamics but can enhance efficiency by improving prioritization and follow-through.`,
    growthAreas: `Refining prioritization skills, improving boundary-setting, and enhancing structured follow-ups can boost effectiveness.`,
  },
  {
    max: 69,
    min: 50,
    title: "Developing",
    implication: `Has the potential to collaborate effectively but may not always balance communication with execution. Could struggle with managing multiple stakeholders.`,
    impact: `Works best in structured team settings with clear roles. Can improve by developing stronger coordination and prioritization skills.`,
    growthAreas: `Learning conflict resolution, practicing structured collaboration, and improving stakeholder management can enhance coordination ability.`,
  },
  {
    max: 49,
    min: 0,
    title: "Limited",
    implication: `Struggles with collaboration, communication, or integrating into team environments. May prefer independent work over coordinating efforts.`,
    impact: `Works best in roles with minimal team dependency. Development in interpersonal skills could improve overall effectiveness.`,
    growthAreas: `Engaging in teamwork exercises, improving communication skills, and learning collaboration frameworks can enhance performance.`,
  },
];

export { WorkStyle };
