import { Button, DoughnutChart } from "components";
import styles from "./styles.module.scss";
import { bucketOptions } from "utils/options";
import { CopyLinkIcon, ExportIconOutline, EyeOpenIcon } from "assets";
import { useState } from "react";
import { copyToClipBoard } from "helpers";
import { MetricHeading } from "../components";
import { Routes } from "router";

export interface Candidate {
  score: number;
  name: string;
  email: string;
  number: string;
  resume: string;
  portfolio: string;
  bucket: string;
  metrics: {
    basicExpectation: number | undefined | null;
    softSkills: number | undefined | null;
    workStyle: number | undefined | null;
    personality: number | undefined | null;
    workQualities: number | undefined | null;
    workMotivators: number | undefined | null;
    domainKnowledge: number | undefined | null;
  };
}

export interface HeaderProps {
  isFullReport: boolean;
  handleView: () => void;
  candidate: Candidate;
  isPublic: boolean;
  isDefault: boolean;
  isAutoScored: boolean;
  role: {
    id: string;
    title: string;
  };
  reportType: string;
}

const Header: React.FC<HeaderProps> = ({
  isFullReport,
  handleView,
  candidate,
  isPublic,
  isDefault,
  isAutoScored,
  role,
  reportType,
}) => {
  const { score, name, number, email, portfolio, resume, bucket, metrics } =
    candidate;

  // copyToClipBoard

  const [copied, setCopied] = useState("");

  const summaries = [
    {
      max: 100,
      min: 90,
      title: "Excellent Fit",
      content: `The assessed candidate is an exemplary fit for the role, showcasing exceptional alignment with the required skills and competencies. This individual meets and exceeds the criteria, indicating a high likelihood of exceptional performance at a ${score}%. Their capabilities and potential suggest a seamless integration into the role, making them a standout candidate.
      `,
    },
    {
      max: 89,
      min: 70,
      title: "Effective Fit",
      content: `The candidate demonstrates a solid alignment with the role, presenting an effective fit with the specified criteria. Their performance potential is notable, and they exhibit the skills and competencies required for success. With a ${score}% fit, this candidate is expected to contribute effectively to the role's responsibilities.`,
    },
    {
      max: 69,
      min: 50,
      title: "Average Fit",
      content: `The candidate showcases an average fit for the role, meeting the baseline requirements and demonstrating competency in key areas. While they may not excel in every aspect, their performance potential suggests a satisfactory fit for the position. A ${score}% fit indicates an ability to perform adequately in the role, with opportunities for growth and development.`,
    },
    {
      max: 49,
      min: 0,
      title: "Low Fit",
      content: `The assessed candidate presents a lower level of alignment with the role, displaying limitations in meeting the specified criteria. Their performance potential may be below the desired threshold, raising concerns about their suitability for the position. With a (${score}% fit, careful consideration is advised to assess whether the candidate's skills and competencies align sufficiently with the role's requirements.`,
    },
  ];

  const keyPoints = [
    {
      max: 100,
      min: 90,
      title: "Excellent Fit",
      basicExpectation: `Exceptional ${metrics.basicExpectation}%`,
      softSkills: `Outstanding ${metrics.softSkills}%`,
      workStyle: `Exemplary ${metrics.workStyle}%`,
      personality: `Exceptional ${metrics.personality}%`,
      workQualities: `Exceptional ${metrics.workQualities}%`,
      workMotivators: `Exceptional ${metrics.workMotivators}%`,
      domainKnowledge: `Exceptional ${metrics.domainKnowledge}%`,
    },
    {
      max: 89,
      min: 70,
      title: "Effective Fit",
      basicExpectation: `Strong ${metrics.basicExpectation}%`,
      softSkills: `Effective ${metrics.softSkills}%`,
      workStyle: `Competent ${metrics.workStyle}%`,
      personality: `Positive ${metrics.personality}%`,
      workQualities: `Competent ${metrics.workQualities}%`,
      workMotivators: `Positive ${metrics.workMotivators}%`,
      domainKnowledge: `Proficient ${metrics.domainKnowledge}%`,
    },
    {
      max: 69,
      min: 50,
      title: "Average Fit",
      basicExpectation: `Satisfactory ${metrics.basicExpectation}%`,
      softSkills: `Adequate ${metrics.softSkills}%`,
      workStyle: `Average ${metrics.workStyle}%`,
      personality: `Moderate ${metrics.personality}%`,
      workQualities: `Satisfactory ${metrics.workQualities}%`,
      workMotivators: `Moderate ${metrics.workMotivators}%`,
      domainKnowledge: `Moderate ${metrics.domainKnowledge}%`,
    },
    {
      max: 49,
      min: 0,
      title: "Low Fit",
      basicExpectation: `Below Expectations ${metrics.basicExpectation}%`,
      softSkills: `Limited ${metrics.softSkills}%`,
      workStyle: `Below Average ${metrics.workStyle}%`,
      personality: `Concerning ${metrics.personality}%`,
      workQualities: `Below Satisfactory ${metrics.workQualities}%`,
      workMotivators: `Limited ${metrics.workMotivators}%`,
      domainKnowledge: `Insufficient ${metrics.domainKnowledge}%`,
    },
  ];
  const candidateSummary = summaries.find(
    (item) => score >= item.min && score <= item.max
  )?.content;

  const candidatePoints = (score: number) =>
    keyPoints.find((item) => score >= item.min && score <= item.max);

  return (
    <section className={`${styles.header} `}>
      <section className={styles.candidate}>
        <div
          className={`${styles.scoreWrap} ${
            isFullReport ? styles["scoreWrap--full"] : ""
          }`}
        >
          <DoughnutChart className={styles.score} score={score} />
          <p
            className={`${
              score >= 90
                ? styles.green
                : score >= 70
                ? styles.blue
                : score >= 50
                ? styles.orange
                : styles.red
            }`}
          >
            <span>{score}</span>
            <span>
              {score >= 90
                ? "Excellent"
                : score >= 70
                ? "Great"
                : score >= 50
                ? "Average"
                : "Low"}
            </span>
          </p>
        </div>

        <div className={styles.info}>
          <div className={styles.info__content}>
            <p className={styles.info__content__hd}>Precision report</p>
            <p className={styles.info__content__name}>
              {name}
              <span
                className={`${styles.status} ${styles[`status__${bucket}`]}`}
              >
                {bucketOptions.find((item) => item.value === bucket)?.label ??
                  bucket}
              </span>
            </p>
            <div className={styles.info__content__contact}>
              <a href={`mailto:${email}`}>{email}</a> | <span>{number}</span>
            </div>

            <div className={styles.info__content__btns}>
              <a href={resume} target="_blank">
                <EyeOpenIcon />
                CV
              </a>

              <Button
                type="outline"
                onClick={() =>
                  copyToClipBoard({
                    link: portfolio,
                    onSuccess: () => {
                      setCopied("cv");

                      setTimeout(() => {
                        setCopied("");
                      }, 1000);
                    },
                    onError: () => setCopied(""),
                  })
                }
              >
                {copied === "cv" ? (
                  "Copied!"
                ) : (
                  <>
                    <CopyLinkIcon />
                    Portfolio Link
                  </>
                )}
              </Button>

              <Button
                className={styles.shareReport}
                type="outline"
                onClick={() =>
                  copyToClipBoard({
                    link: `${
                      window.location.origin
                    }${Routes.employerReportPublic({
                      id: role.id,
                      role: role.title.replaceAll(/[^a-zA-Z\d:]/g, "_"),
                    })}`,
                    onSuccess: () => {
                      setCopied("report");

                      setTimeout(() => {
                        setCopied("");
                      }, 1000);
                    },
                    onError: () => setCopied(""),
                  })
                }
              >
                {copied === "report" ? (
                  "Copied!"
                ) : (
                  <>
                    <CopyLinkIcon />
                    Share report
                  </>
                )}
              </Button>

              {!isPublic ? (
                <Button
                  className={`${styles.viewReport} ${
                    isFullReport ? styles["viewReport--full"] : ""
                  }`}
                  type="outline"
                  onClick={handleView}
                >
                  {isFullReport ? (
                    "Back to Dashboard"
                  ) : (
                    <>
                      <EyeOpenIcon /> View full report
                    </>
                  )}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
      {reportType === "observative" ? (
        <section className={styles.summaryBody}>
          <MetricHeading heading="Overview" />
          <p className={styles.summaryBody__summary}>
            This report offers an observational perspective on the individual’s
            dynamics within the context of the assessment administered. It
            provides insights into various aspects of their behavior,
            preferences, and performance potential, offering a holistic view of
            their fit for the role. This report serves as a way for them to
            better understand their strengths, areas for development, and
            overall alignment with the role.
          </p>
          <div className={styles.summaryBody__points}>
            <p>Key Insights</p>
            <ul>
              {metrics.softSkills !== undefined ? (
                <li>
                  Soft Skills: Key abilities that enable high-quality work and
                  performance.
                </li>
              ) : (
                ""
              )}
              {metrics.workStyle !== undefined ? (
                <li>
                  Work Style: Individual preferences and approach to completing
                  tasks.
                </li>
              ) : (
                ""
              )}
              {metrics.personality !== undefined ? (
                <li>
                  Personality: Character traits affecting various aspects of
                  work.
                </li>
              ) : (
                ""
              )}
              {metrics.workQualities !== undefined ? (
                <li>
                  Workplace Qualities: Traits that contribute to a positive work
                  environment.
                </li>
              ) : (
                ""
              )}
              {metrics.workMotivators !== undefined ? (
                <li>
                  Work Motivators: Factors driving work habits and engagement.
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </section>
      ) : (
        <section className={styles.summaryBody}>
          <MetricHeading heading="Summary" />
          <p className={styles.summaryBody__summary}>{candidateSummary}</p>
          <div className={styles.summaryBody__points}>
            <p>
              Based on the expectations that you have defined, this is how the
              candidate scores and ranks:
            </p>
            <ul>
              {metrics.basicExpectation !== undefined ? (
                <li>
                  Basic Expectations:{" "}
                  {metrics.basicExpectation === null
                    ? "--"
                    : candidatePoints(metrics.basicExpectation)
                        ?.basicExpectation}
                </li>
              ) : (
                ""
              )}
              {metrics.softSkills !== undefined ? (
                <li>
                  Soft Skills:{" "}
                  {metrics.softSkills === null
                    ? "--"
                    : candidatePoints(metrics.softSkills)?.softSkills}
                </li>
              ) : (
                ""
              )}
              {metrics.workStyle !== undefined ? (
                <li>
                  Work Style:{" "}
                  {metrics.workStyle === null
                    ? "--"
                    : candidatePoints(metrics.workStyle)?.workStyle}
                </li>
              ) : (
                ""
              )}
              {metrics.personality !== undefined ? (
                <li>
                  Personality:{" "}
                  {metrics.personality === null
                    ? "--"
                    : candidatePoints(metrics.personality)?.personality}
                </li>
              ) : (
                ""
              )}
              {metrics.workQualities !== undefined ? (
                <li>
                  Workplace Qualities:{" "}
                  {metrics.workQualities === null
                    ? "--"
                    : candidatePoints(metrics.workQualities)?.workQualities}
                </li>
              ) : (
                ""
              )}
              {metrics.workMotivators !== undefined ? (
                <li>
                  Work Motivators:{" "}
                  {metrics.workMotivators === null
                    ? "--"
                    : candidatePoints(metrics.workMotivators)?.workMotivators}
                </li>
              ) : (
                ""
              )}
              {metrics.domainKnowledge !== undefined ? (
                <li>
                  Domain Knowledge:{" "}
                  {metrics.domainKnowledge === null ? (
                    "--"
                  ) : (isDefault && isAutoScored) || !isDefault ? (
                    candidatePoints(metrics.domainKnowledge)?.domainKnowledge
                  ) : (
                    <em style={{ fontWeight: "500" }}>
                      You haven't generated a score for this candidate
                    </em>
                  )}
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </section>
      )}
    </section>
  );
};

export { Header };
